import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Divider, Loader } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { Link } from 'react-router';
import axios from 'axios';
import cookie from 'react-cookie';

import PhoneInput from '../../components/PhoneInput';
import TextInput from '../../components/TextInput';
import FieldInput from '../../components/FieldInput';
import Responsive from '../../components/Responsive';
import Modal from '../../components/Modal';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';
import Footer from './Footer';

import i18n, { __ } from '../../i18n';
import parse from '../../lib/parse';
import * as utils from '../../utils';

const errorMap = {
  email: __('Email address is not valid: check domain name and if it contains @ and .'),
  phone: __('Phone number is not valid: check area code and number length'),
  fullname: __('Fullname is required'),
  role: __('Required field')
};

@graphql(gql`mutation createCodeAndSend($input: CreateCodeAndSendInput!) {
  createCodeAndSend(input: $input){
    status
  }
}
`, { name: 'createCodeAndSend' },
)
@graphql(gql`mutation findUserEmail($email: String, $invite: Boolean, $isNewCode: Boolean) {
  findUser(email: $email, invite: $invite, source: WEB, isNewCode: $isNewCode) {
    __typename
    ... on User {
        fullname
        email
        phone
        hasPassword
      }
      ... on PublicAddress {
        address
      }
  }
}`, { name: 'findUserEmail' },
)
@graphql(gql`mutation findUserPhone($phone: String, $invite: Boolean, $isNewCode: Boolean) {
  findUser(phone: $phone, invite: $invite, source: WEB, isNewCode: $isNewCode) {
    __typename
    ... on User {
        fullname
        email
        phone
        hasPassword
      }
      ... on PublicAddress {
        address
      }
  }
}`, { name: 'findUserPhone' },
)

@inject('store', 'client', 'api') @observer
export default class User extends Responsive {
  constructor(props) {
    super(props);
    const { user } = props.store.prospect;
    const prospectType = props.store.auth.prospectType;
    let phoneFormatted = '';
    if (user) {
      if (props.store.auth) {
        phoneFormatted = user.phoneFormatted || props.store.auth.phoneFormatted;
      }
    }

    this.state = {
      email: (user && user.email) ? user.email : (prospectType === 'email' ? props.store.auth.contact : ''),
      errors: [],
      fullname: (user && user.fullname) || '',
      loading: true,
      phone: (user && user.phone) ? user.phone : (prospectType === 'phone' ? props.store.auth.contact : ''),
      phoneFormatted: phoneFormatted || '',
      checked: false
    };
  }

  UNSAFE_componentWillMount() {
    const { user } = this.props.store.prospect;

    if (user) {
      const { fullname, checked } = user;
      this.setState({
        fullname,
        checked
      });
    }
  }

  componentDidMount() {
    const { store, router } = this.props;
    const profileType = store && store.prospect.user && store.prospect.user.profileType;

    if (store.accessToken && !store.prospect.user) {
      router.push('/');
    }

    if (!profileType) {
      router.push('/trial');
    }

    this.setState({ loading: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
  }

  validate = () => {
    const { fullname, email, phone } = this.state;

    const validFullname = !!fullname.trim();
    const validEmail = !!email && parse.validateEmail(email);
    const validPhone = !!phone && parse.validatePhone('', phone);

    const valid = validEmail && validFullname && validPhone;

    if (valid) {
      this.setState({ errors: [] });
      this.submit();
    } else {
      const errors = [];
      if (!validFullname) errors.push('fullname');
      if (!validEmail) errors.push('email');
      if (!validPhone) errors.push('phone');
      this.setState({ errors });
    }
  }

  handleLogin = (type, data) => {
    const { store, router } = this.props;

    if (type && data) {
      const { fullname, email, phone, hasPassword, shouldAskForPassword } = data.data.findUser;

      store.auth.user = { fullname, email, phone, hasPassword, shouldAskForPassword, methodType: 'trial-login' };
      store.auth.type = type;
      store.auth.phone = data.data.findUser.phone;
      store.auth.email = data.data.findUser.email;

      router.push('/auth/login');
    } else {
      router.push('/auth');
    }
  }

  sendCodeToPhone = () => {
    const { phone } = this.state;
    try {
      this.props.createCodeAndSend({
        variables: {
          input: {
            address: phone.replace(/[^0-9]+/g, ''),
            type: 'phone',
            language: i18n.currentLocale()
          }
        }
      });
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }

  submit = () => {
    const { store } = this.props;
    const { email, phone, } = this.state;
    const params = {};

    if (!navigator.onLine) {
      this.props.store.snackbar = { active: true, message: __('No connection available'), dismissAfter: 4000 };
      return null;
    }

    params.phone = phone.replace(/[^0-9]+/g, '');
    params.email = email;

    this.setState({ loading: true });

    this.props.findUserPhone({
      variables: {
        phone: params.phone,
        invite: true,
        isNewCode: true
      }
    }).then((data) => {
      if (data.data.findUser.__typename === 'User') {
        this.handleLogin('phone', data);
      } else {
        throw new Error('Next step');
      }
    }).catch((e) => {
      this.props.findUserEmail({
        variables: {
          email: params.email,
          invite: true,
          isNewCode: true
        }
      }).then((data) => {
        if (data.data.findUser.__typename === 'User') {
          this.handleLogin('email', data);
        } else {
          throw new Error('Next step');
        }
      }).catch(async (e) => {
        //User not found
        const { fullname, checked, phoneFormatted } = this.state;

        store.prospect.user = {
          ...store.prospect.user,
          fullname,
          email,
          phone: params.phone,
          phoneFormatted,
          checked
        };
        if (store.prospect.user.profileType === 'INSTITUTION') {
          store.prospect.user1 = {
            ...store.prospect.user,
            fullname,
            email,
            phone: params.phone,
            phoneFormatted,
            checked
          };
        } else if (store.prospect.user.profileType === 'TEACHER') {
          store.prospect.user2 = {
            ...store.prospect.user,
            fullname,
            email,
            phone: params.phone,
            phoneFormatted,
            checked
          };
        }

        if (store.app && store.app.env === 'production') {
          const { user } = store.prospect;
          this.sendFormToHubspot(user, this.isMobile());
        }
        this.sendCodeToPhone();

        this.setState({ loading: false });
        this.props.router.push('/trial/code');
      });
    });
  };

  renderForm = () => {
    const { errors, fullname, email, phone, checked } = this.state;
    return (
      <div>
        <div style={{ maxWidth: '460px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '10px' }}>
          <Form>
            <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 8, marginTop: 0 }}>{__('Sign up on ClassApp')}</p>
            <p style={{ marginTop: 0, marginBottom: 30, fontSize: 15, color: '#6C6C6C' }}>{__('You will be the admin of the institution.')}</p>
            <TextInput
              autoFocus
              name="fullname"
              value={fullname}
              placeholder={__('Type your name here...')}
              label={__('Fullname')}
              error={errors.indexOf('fullname') !== -1 && errorMap.fullname}
              onChange={(e, data) => this.setState({ fullname: data.value })}
            />
            <FieldInput
              name="phone"
              label={__('Phone')}
              placeholder={__('Area Code + Phone')}
              error={errors.indexOf('phone') !== -1 && errorMap.phone}
              control={PhoneInput}
              defaultValue={phone}
              onChange={value => this.setState({ phone: value, phoneFormatted: value })}
            />
            <TextInput
              name="email"
              type="email"
              label={__('Email')}
              placeholder={__('your@email.com')}
              value={email}
              icon="envelope"
              iconPosition="left"
              error={errors.indexOf('email') !== -1 && errorMap.email}
              onChange={(e, data) => this.setState({ email: data.value })}
            />
            <div style={{ marginTop: '30px', display: 'flex' }}>
              <ColoredCheckbox
                radio
                radioChecked
                data-id="whatsapp"
                data-action="check"
                checked={checked}
                onClick={() => this.setState({ checked: !this.state.checked })}
                style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '10px' }}
              />
              <p>{__('I agree to receive information via WhatsApp')}</p>
            </div>
          </Form>
        </div>
        {this.props.children}
      </div>
    );
  }

  sendFormToHubspot = async (user, isMobile) => {
    const FORM_ID = 'ce08576d-3faf-4864-8c2e-733affec97f1';
    const HUBSPOTUTK = cookie.load('hubspotutk'); // campo usado pelo hubspot para trackear o usuario

    await axios({
      method: 'post',
      url: `https://api.hsforms.com/submissions/v3/integration/submit/5967222/${FORM_ID}`,
      data: {
        fields: [
          { name: 'fullname', value: user.fullname },
          { name: 'email', value: user.email },
          { name: 'mobilephone', value: user.phone },
          { name: 'optinwhatsapp', value: user.checked ? 'true' : 'false' },
          { name: 'ismobile', value: isMobile ? 'true' : 'false' }
        ],
        context: {
          hutk: HUBSPOTUTK,
          pageUri: 'https://classapp.com.br/trial/user',
          pageName: `${__('Sign Up')} · ClassApp`
        }
      }
    }).catch(e => console.error(e));
  }

  render() {
    const { loading, email, phone, fullname } = this.state;
    const disabled = !email || !phone || !fullname;

    if (loading) {
      return (<div id="FreeUser">
        <Divider hidden />
        <div style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
          <Loader active inline />
        </div>
        {this.props.children}
      </div>
      );
    }

    return (
      <div id="FreeUser">
        <div style={{ width: '100%' }}>
          {this.renderForm()}
          <div style={{ maxWidth: '460px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '10px' }}>
            <Footer
              before={(
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'row', marginBottom: '20px' }}>
                  <span>
                    {__('By continuing you agree to our ')}
                    <Link target="_blank" to={'/terms?type=admin+staff'}> {__('Terms and Privacy')} </Link>
                  </span>
                </div>)}
              loading={loading}
              primary={{ label: __('Next step'), handleClick: this.handleSubmit, disabled }}
              secondary={{ label: __('I`m already registered'), handleClick: () => this.handleLogin() }}
            />
          </div>
        </div>
      </div>
    );
  }
}
