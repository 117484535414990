import React from 'react';
import { Button } from 'semantic-ui-react';

/*
  Using component, Eg:

  <Footer
    primary={{ label: 'Button 1', handleClick: () => {}, disabled: false }}
    secondary={{ label: 'Button 2', handleClick: () => {}, disabled: false }}
  />
*/

const styles = {
  footer: {
    position: 'static',
    borderTop: '0px',
    marginTop: '10px',
    paddingBottom: '50px',
  },
  primary: {
    backgroundColor: '#0069FF',
    borderRadius: '48px',
    color: '#FFF',
    height: '48px',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  secondary: {
    backgroundColor: '#FFFFFF',
    borderRadius: '48px',
    border: '1px solid #0069FF',
    color: '#0069FF',
    height: '48px',
    paddingTop: '5px',
    paddingBottom: '5px',
    marginTop: '20px'
  }
};

export default ({ secondary, primary, loading, before = null }) => {
  if (!primary) return null;

  return (
    <div style={{ ...styles.footer }}>
      {before}
      <Button disabled={primary.disabled} fluid style={{ ...styles.primary }} loading={loading} onClick={primary.handleClick}>
        <span style={{ fontSize: '15px' }}>{primary.label}</span>
      </Button>
      {secondary ?
        <Button disabled={secondary.disabled} fluid style={{ ...styles.secondary }} loading={loading} onClick={secondary.handleClick}>
          <span style={{ fontSize: '15px' }}>{secondary.label}</span>
        </Button> : null}
    </div>);
};

