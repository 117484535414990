/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Image } from 'semantic-ui-react';

import Responsive from '../../components/Responsive';

import i18n, { __ } from '../../i18n';
import config from '../../../../config/server.config';
import * as utils from '../../utils';

const styles = {
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 28,
    color: '#1D1D1D',
    marginBottom: 15,
    marginTop: 0
  },
  subtitle: {
    marginBottom: 20,
    fontSize: 15,
    color: '#6C6C6C',
  },
  mobileFooter: {
    backgroundColor: '#0069FF',
    borderRadius: '40px',
    color: '#FFF',
    paddingTop: '20px',
    paddingBottom: '20px',
    position: 'absolute',
    bottom: '0px',
    width: '100%'
  },
  footer: {
    position: 'fixed',
    bottom: '0px',
    left: '50%',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginLeft: '-50%',
    backgroundColor: 'white',
  },
};

@inject('store') @observer
export default class ProspectThank extends Responsive {
  static fetchData({ store }) {
    store.app.title = __('Thank You');
  }

  mobileFooter = () => (
    <Button as="a" href="https://www.classapp.com.br/lp-pais" style={styles.mobileFooter}>
      <span style={{ fontSize: '15px' }}>{__('See how to help here')}</span>
    </Button>
  )

  render() {
    const isMobile = this.isMobile();
    return (
      <div id="ProspectThank" style={styles.form}>
        <p style={styles.title}>{__('Thank you for the contact!')}</p>
        <p style={styles.subtitle}> {__('Is your school already a customer? Contact them and ask to register your access data.')}</p>
        <p style={styles.subtitle}> {__('If your school still doesn\'t use ClassApp, ask them to register on our website!')}</p>
        <Image src={utils.asset('/onboarding/thank-ilustracao.png')} style={{ maxHeight: 340 }} centered />
        {!isMobile ?
          <div style={styles.footer}>
            <Button as="a" href="https://www.classapp.com.br/lp-pais" primary labelPosition="right" icon="arrow right" content={__('See how to help here')} />
          </div>
          : this.mobileFooter()
        }
      </div>
    );
  }
}
