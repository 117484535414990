import React from 'react';
import { Button, Popup, Image, Dropdown, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import cookie from 'react-cookie';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import axios from 'axios';

import Responsive from '../../components/Responsive';
import { roleOptions } from './helpers/getRoleOptions';
import { defaultTable } from './helpers/getDefaultTable';
import { typeOptions } from './helpers/getTypeOptions';

import i18n, { __ } from '../../i18n';
import parse from '../../lib/parse';
import * as utils from '../../utils';

const styles = {
  header: {
    maxWidth: '460px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '10px',
  },
  cell: {
    maxWidth: '260px',
    minWidth: '260px',
    textAlign: 'left',
    border: 'none',
    padding: '7px 10px',
    overflow: 'hidden',
    cursor: 'text',
  },
  row: {
    width: '645px',
  },
  container: {
    borderTop: '1px solid rgba(0, 0, 0, .1)',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    fontWeight: 'bold'
  },
  text: {
    fontSize: 15,
    color: '#6C6C6C',
    margin: '15px 0px'
  },
  footer: {
    position: 'static',
    borderTop: '0px',
    maxWidth: '460px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px'
  }
};

@inject('store', 'client', 'api')
@graphql(gql`mutation createOrganization($createOrganizationMutation: CreateOrganizationInput!) {
  createOrganization(input: $createOrganizationMutation) {
    organization {
      id: dbId
      user {
        language
        oauthProvider {
          accessToken
          refreshToken
        }
      }
    }
    clientMutationId
  }
}`, {
  name: 'createOrganization'
})
@graphql(gql`mutation updateUser($updateUserMutation: UpdateUserInput!) {
  updateUser(input: $updateUserMutation) {
    user {
      oauthProvider {
        accessToken
        refreshToken
      }
    }
    clientMutationId
  }
}
`, {
  name: 'updateUser'
})

@graphql(gql`mutation createAcceptedTermMutation($createAcceptedTermInput: CreateAcceptedTermInput!) {
  createAcceptedTerm(input: $createAcceptedTermInput) {
    clientMutationId
  }
}
`, {
  name: 'createAcceptedTerm'
})

@graphql(gql`mutation prospectCreateGroup($createGroupInput: CreateGroupInput!) {
    createGroup(input: $createGroupInput) {
      group {
        id: dbId
      }
    }
  }`, {
  name: 'createGroup'
})
@graphql(gql`mutation prospectCreateEntity($createEntityInput: CreateEntityInput!) {
  createEntity(input: $createEntityInput) {
    entity {
      id: dbId
    }
  }
}`, {
  name: 'createEntity'
})
@graphql(gql`mutation prospectUpdateOrganization($updateOrganizationMutation: UpdateOrganizationInput!) {
    updateOrganization(input: $updateOrganizationMutation) {
      clientMutationId
    }
}`, {
  name: 'updateOrganization'
})
@graphql(gql`mutation WalleRegisterMarketingPQL ($input: RegisterMarketingPQLInput!) {
  registerMarketingPQL(input: $input) {
    clientMutationId
  }
}`, {
  name: 'registerMarketingPQL'
})
@observer
export default class ProspectEntities extends Responsive {
  constructor(props) {
    super(props);
    this.state = {
      table: [],
      fullnameErrors: [],
      phoneErrors: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { entities, user, organization } = this.props.store.prospect;

    if (!user) {
      this.props.router.push('/trial');
    } else if (!organization) {
      this.props.router.push('/trial/organization');
    } else {
      let table = cloneDeep(defaultTable());
      if (entities && entities.length) {
        table = [];

        entities.forEach((entity, i) => {
          table.push([{ value: (i + 1).toString(), readOnly: true }, { value: '', placeHolder: __('Enter a name here') }, { value: '', placeHolder: __('Eg: (00) 0 0000-0000') }, { value: 'STAFF' }]);

          if (entity.fullname) { table[i][1].value = entity.fullname; }
          if (entity.phone) { table[i][2].value = entity.phone; }
          if (entity.type) { table[i][3].value = entity.type; }
        });
      }

      this.setState({ table });
    }
  }

  componentWillUnmount() {
    if (!this.state.onLeave) {
      const { table } = this.state;
      const data = [];

      table.forEach((row, i) => {
        if (row[1].value !== '' || row[2].value !== '') {
          data.push({ fullname: row[1].value, phone: row[2].value, type: row[3].value, index: i });
        }
      });

      this.props.store.prospect.entities = data;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { table } = this.state;
    const isNotEmpty = table.find(row => !!row[1].value || !!row[2].value);
    if (isNotEmpty) this.props.store.appends.pop();
    this.validate();
  }

  handleChange = (key, id, e) => {
    const newTable = this.state.table.map(row => [...row]);
    newTable[key][id].value = id === 2 ? utils.formatPhone(e.target.value) : e.target.value;
    this.setState({ table: newTable });
  }

  validate = async () => {
    const { table } = this.state;
    const fullnameErrors = [];
    const phoneErrors = [];
    const data = [];

    table.forEach((row, i) => {
      if (row[1].value.trim() !== '' || row[2].value.trim() !== '') {
        data.push({ fullname: row[1].value, phone: row[2].value, type: row[3].value, index: i });
      }
    });

    await data.forEach((row) => {
      if (row.phone.trim() !== '' && !parse.validatePhone('+55', row.phone)) {
        phoneErrors.push(row.index);
      }
      if (row.fullname.trim() === '' || data.filter(r => r.fullname.trim() === row.fullname.trim()).length > 1) {
        fullnameErrors.push(row.index);
      }
    });

    if (fullnameErrors.length === 0 && phoneErrors.length === 0) {
      this.setState({ fullnameErrors: [], phoneErrors: [] });
      this.submit(data);
    } else {
      this.setState({ fullnameErrors, phoneErrors });
    }
  }

  submit = async (data) => {
    const { store } = this.props;

    store.prospect.entities = data;

    this.setState({ loading: true });

    const organization = await this.createOrganization();

    this.createGroup(organization.id, organization.adminId);
  }

  createOrganization = async () => {
    const { store, api } = this.props;
    const user = store.prospect.user;
    const organization = store.prospect && store.prospect.organization;
    const values = {
      fullname: organization.fullname,
      type: 'SCHOOL',
      usage: 'CLIENT',
      entryMethod: 'ONBOARDING',
      language: 'pt',
      plan: 'FREE',
      defaultFeatures: ['TAGS', 'REPORTS', 'LABELS', 'FORMS'],
      confCharge: true
    };

    values.entity = {
      fullname: user.fullname,
      defaultFeatures: ['MESSAGE'],
      description: roleOptions().find(role => role.value === user.role || role.value === organization.role).text,
      seeAll: true,
      invisible: true
    };

    if (user) {
      if (user.includeViewer) {
        values.includeViewer = true;
      } else {
        const { fullname, email, phone } = user;
        values.user = { fullname, email, phone, language: 'pt', isNewCode: true, verifiedAddressCode: user.code };
      }
    }

    try {
      const createOrganization = await this.props.createOrganization({
        variables: {
          createOrganizationMutation: {
            ...values
          }
        }
      });

      const organizationData = createOrganization && createOrganization.data && createOrganization.data.createOrganization;

      const { oauthProvider, language } = organizationData.organization.user;
      const { accessToken, refreshToken } = oauthProvider;
      cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
      cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });

      store.access_token = accessToken;
      store.refresh_token = refreshToken;
      api.access_token = accessToken;

      if (language) {
        cookie.save('lang', language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
        store.app.locale = language;
        i18n.locale = store.app.locale;
      }

      const organizationId = organizationData.organization.id;

      await this.props.updateUser({
        variables: {
          updateUserMutation: {
            tzOffset: -(new Date().getTimezoneOffset()),
            isNewCode: true
          }
        }
      });

      await this.props.createAcceptedTerm({
        variables: {
          createAcceptedTermInput: {}
        }
      });

      if (store.app.env === 'production') {
        await this.sendPQLToHubspot(organizationId, organization, user, this.isMobile());

        await this.props.registerMarketingPQL({
          variables: {
            input: {
              organizationId,
              organizationSize: `${organization.size}`,
              platform: this.isMobile() ? 'Mobile' : 'Desktop',
            },
          },
        });
      }

      const entitiesQuery = await this.props.client.query({
        query: gql`query EntitiesQuery (
          $id: ID!,
          $limit: Int,
          $entityType: [EntityType]
        ) {
            node(id: $id) @connection(key: "Organization", filter: ["id"]) {
              ... on Organization {
                id: dbId
                entities (limit: $limit, type: $entityType) {
                  nodes {
                    id: dbId
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: organizationId,
          limit: 1,
          entityType: ['ADMIN']
        }
      });

      const adminId = entitiesQuery.data.node.entities.nodes[0].id;

      store.prospect.organization = {
        ...store.prospect.organization,
        id: organizationId,
        adminId
      };

      return {
        ...store.prospect.organization,
        id: organizationId,
        adminId
      };
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }

  createGroup = async (organizationId, adminId) => {
    const { createGroup } = this.props;
    const values = {
      name: __('Students')
    };

    createGroup({
      variables: {
        createGroupInput: {
          organizationId,
          ...values
        }
      }
    }).then(async (groupData) => {
      this.createEntities(organizationId, adminId, groupData.data.createGroup.group.id);
    }).catch((err) => {
      this.setState({ loading: false });
      console.error(err);
    });
  }

  createEntities = async (organizationId, adminId, groupId) => {
    const { store, createEntity, updateOrganization, router } = this.props;
    const { entities } = store.prospect;

    entities.forEach(async (entity) => {
      const entityValues = {
        organizationId,
        fullname: entity.fullname,
        type: entity.type,
        defaultFeatures: ['MESSAGE'],
        addresses: entity.phone ? [{ address: '55' + entity.phone, type: 'PHONE' }] : [],
        groups: entity.type === 'STUDENT' ? [{ id: groupId }] : [],
        seeAll: entity.type === 'STAFF',
        invisible: entity.type === 'STAFF'
      };

      await createEntity({
        variables: {
          createEntityInput: {
            ...entityValues
          }
        }
      }).catch(e => console.error(e));
    });

    // send invites
    await updateOrganization({
      variables: {
        updateOrganizationMutation: {
          id: organizationId,
          invite: 'LIVE'
        }
      }
    }).catch(e => console.error(e));

    store.prospect.finished = {
      organizationId,
      adminId,
      groupId
    };

    const finished = store && store.prospect && store.prospect.finished;

    if (!this.isMobile()) {
      setTimeout(() => {
        this.setState({ onLeave: true }, () => router.push(`/organizations/${finished.organizationId}/messages`));
      }, 5000);
    } else {
      this.setState({ onLeave: true }, () => router.push('/trial/finish'));
    }
  }

  addEntity = () => {
    const { table } = this.state;

    table.push([{ value: (table.length + 1).toString(), readOnly: true }, { value: '', placeHolder: __('Enter a name here') }, { value: '', placeHolder: __('Eg: (00) 0 0000-0000') }, { value: 'STAFF' }]);

    this.setState({ table });
  }

  sendPQLToHubspot = async (organizationId, organization, user, isMobile) => {
    const FORM_ID = '21050cb2-736a-40db-b423-f93a560e3afa';
    const HUBSPOTUTK = cookie.load('hubspotutk'); // campo usado pelo hubspot para trackear o usuario

    await axios({
      method: 'post',
      url: `https://api.hsforms.com/submissions/v3/integration/submit/5967222/${FORM_ID}`,
      data: {
        fields: [
          { name: 'fullname', value: user.fullname },
          { name: 'email', value: user.email },
          { name: 'mobilephone', value: user.phone },
          { name: 'company', value: organization.fullname },
          { name: 'ocupa_o', value: user.role || organization.role },
          { name: 'alunos_predefinido', value: organization.size },
          { name: 'classappid', value: organizationId },
          { name: 'optinwhatsapp', value: user.checked || organization.checked ? 'true' : 'false' },
          { name: 'ismobile', value: isMobile ? 'true' : 'false' }
        ],
        context: {
          hutk: HUBSPOTUTK,
          pageUri: 'https://classapp.com.br/trial/entities',
          pageName: `${__('Sign Up')} · ClassApp`
        }
      }
    }).catch(e => console.error(e));
  }

  onDropdownChange = (value, cellProps) => {
    const newTable = this.state.table.map(row => [...row]);

    newTable[cellProps.row][cellProps.col].value = value;

    this.setState({ table: newTable });
  }

  renderFooter = () => {
    const { loading, table } = this.state;
    const isNotEmpty = table.find(row => !!row[1].value || !!row[2].value);
    const hasPhoneNumber = table.find(row => !!row[2].value);
    const buttonLabel = isNotEmpty && hasPhoneNumber ? __('Invite') : isNotEmpty && !hasPhoneNumber ? __('Conclude') : __('Skip step and conclude');
    return (
      <div style={{ ...styles.footer }}>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '24px 20px 0px 20px' }}>
          <Button fluid basic primary style={{ borderRadius: '40px', paddingTop: '20px', paddingBottom: '20px' }} onClick={this.addEntity} disabled={loading}>
            <span style={{ fontSize: '15px', color: '#0069FF' }}>{__('Add More')}</span>
          </Button>
        </div>
        <div style={{ ...styles.container, borderTop: '0px' }}>
          <Button fluid style={{ backgroundColor: '#0069FF', borderRadius: '40px', color: '#FFF', paddingTop: '20px', paddingBottom: '20px' }} loading={loading} onClick={this.handleSubmit} disabled={loading}>
            <span style={{ fontSize: '15px' }}>{buttonLabel}</span>
          </Button>
        </div>
      </div>);
  }

  renderTutorial = () => <Popup
    trigger={<span style={{ color: '#0069ff' }}>{__('clicking here.')}</span>}
    content={<div><Image src={utils.asset('/gif/addStudents.gif')} size="big" style={{ maxWidth: 'none' }} /></div>}
    hideOnScroll
    basic
    position="bottom right"
    on="click"
  />

  renderTable = (isMobile, table, phoneErrors, fullnameErrors, organization) => {
    let errorMessage = [];

    return (
      <table style={isMobile ? { borderCollapse: 'collapse', width: '100%', height: '100%' } : { borderCollapse: 'collapse', width: '578px', height: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
        <tr style={{ borderBottom: '1pt solid #D2D2D2', height: '50px' }}>
          <td style={{ paddingLeft: '10px' }}>#</td>
          <td style={{ paddingLeft: '2px' }}>{__('Name')}</td>
          <td style={{ paddingLeft: '2px' }}>{__('Phone')}</td>
          <td style={{ paddingLeft: '2px' }}>{__('Type')}</td>
        </tr>
        {table.map((row, key) => {
          const isErrorPhone = phoneErrors.includes(key);
          const isErrorFullname = fullnameErrors.includes(key);

          if (fullnameErrors.indexOf(key) !== -1) {
            errorMessage.push(<span>{__('Fullname is not valid: fullname cannot be empty or duplicated')}</span>);
          }
          if (phoneErrors.indexOf(key) !== -1) {
            if (errorMessage.length) errorMessage = errorMessage.concat([<br />, <br />]);
            errorMessage.push(<span>{__('Phone number is not valid: check area code and number length')}</span>);
          }

          return (
            <tr
              style={{
                borderBottom: '1pt solid #D2D2D2',
                height: '55px',
              }}
              onKeyPress={(e) => {
                if (key == table.length - 1 && e.key === 'Enter' && (table[key][1].value || table[key][2].value)) {
                  this.addEntity();
                }
              }}
            >
              {row.map((col, id) => (
                <td
                  style={{
                    borderBottom: '1pt solid #D2D2D2',
                    paddingLeft: id === 0 ? '10px' : '',
                    minWidth: (id === 1 || id === 2) ? '174px' : ''
                  }}
                >
                  {(id === 1 && isErrorFullname === true) || (id === 2 && isErrorPhone === true) ? <Icon name="warning sign" color="red" /> : ''}
                  {id === 0 ?
                    col.value :
                    id === 1 ?
                      <input autoFocus={key !== 1} style={{ border: 'none', outline: 'none' }} onChange={e => this.handleChange(key, id, e)} placeholder={col.placeHolder} value={col.value} /> :
                      id === 2 ?
                        <input style={{ border: 'none', outline: 'none' }} onChange={e => this.handleChange(key, id, e)} placeholder={col.placeHolder} value={col.value} /> :
                        <Dropdown
                          fluid
                          style={{
                            width: '100px',
                          }}
                          data-name="entityType"
                          options={typeOptions(organization.type)}
                          value={col.value}
                          onChange={(e, { value }) => this.onDropdownChange(value, { row: key, col: 3 })}
                        />}
                </td>
              ))}
            </tr>
          );
        })
        }
      </table >
    );
  };

  render() {
    const { table, fullnameErrors, phoneErrors } = this.state;
    const { organization } = this.props.store.prospect;
    const isMobile = this.isMobile();

    return (
      <div id="ProspectEntities">
        <div style={styles.header}>
          <div style={{ textAlign: 'left' }}>
            <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 8, marginTop: 0 }}>{__('Invite people')}</p>
            <p style={{ fontSize: 15, color: 'rgba(0, 0, 0, 0.8)', margin: '5px 0 5px 0', fontWeight: 400 }}>{__('Want to get someone to test ClassApp with you?')}</p>
            <p style={{ fontSize: 15, color: 'rgba(0, 0, 0, 0.8)', margin: '5px 0 5px 0', fontWeight: 400 }}>{__('Add here. If you prefer, you can do this later.')}</p>
            {fullnameErrors.length > 0 ? <p style={{ fontSize: 15, color: 'red', margin: '9px 0 5px 0' }}>{__('Fullname is not valid: fullname cannot be empty or duplicated')}</p> : ''}
            {phoneErrors.length > 0 ? <p style={{ fontSize: 15, color: 'red', margin: '9px 0 5px 0' }}>{__('Phone number is not valid: check area code and number length')}</p> : ''}
          </div>
        </div>
        <div style={isMobile ? { display: 'block', overflowX: 'auto', width: '100%', marginTop: '19px' } : { display: 'block', overflowY: 'visible', width: '100%', marginTop: '19px' }}>
          {this.renderTable(isMobile, table, phoneErrors, fullnameErrors, organization)}
        </div>
        {this.renderFooter()}
        {this.props.children}
      </div>
    );
  }
}
