import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Loader, Image, Button } from 'semantic-ui-react';

import Responsive from '../../components/Responsive';
import { __ } from '../../i18n';
import { asset } from '../../utils';

const styles = {
  footer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '40px',
    border: '1px solid #0069FF',
    color: '#0069FF',
    padding: '20px 0px',
    marginTop: '20px'
  },
  button: {
    border: '1px solid #D2D2D2',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 0px',
    padding: '20px 32px 20px 32px',
    alignItems: 'center',
    textAlign: 'left',
    cursor: 'pointer'
  }
};

@inject('store', 'client') @observer
export default class Free extends Responsive {
  constructor(props) {
    super(props);
    this.profileOptions = [
      { title: __('I am a manager'), subtitle: __('I am a director, coordinator, maintainer or I am part of the management of an institution.'), assets: '/demonstration/institution.png', profileType: 'INSTITUTION' },
      { title: __('I am a teacher'), subtitle: __('I am a teacher and would like to test how ClassApp works for use in my school.'), assets: '/demonstration/teacher.png', profileType: 'TEACHER' },
      { title: __('I am a guardian'), subtitle: __('I am responsible for a student and I want to use ClassApp at my child`s school.'), assets: '/demonstration/parent.png', profileType: 'PARENT' },
      { title: __('I am a student'), subtitle: __('I am a student and would like to test how ClassApp works for use in my school.'), assets: '/demonstration/student.png', profileType: 'STUDENT' },
    ];
    this.state = {
      loading: false
    };
  }

  handleRoleClick = (profileType) => {
    const { store, router } = this.props;
    if (profileType === 'INSTITUTION' && store.prospect.user1) {
      store.prospect.user = {
        ...store.prospect.user1,
        profileType,
        role: profileType === 'TEACHER' ? 'TEACHER' : null
      };
    } else if (profileType === 'TEACHER' && store.prospect.user2) {
      store.prospect.user = {
        ...store.prospect.user2,
        profileType,
        role: profileType === 'TEACHER' ? 'TEACHER' : null
      };
    } else {
      store.prospect.user = {
        profileType,
        role: profileType === 'TEACHER' ? 'TEACHER' : null
      };
    }

    if (profileType.includes('PARENT') || profileType.includes('STUDENT')) {
      router.push('/simulation');
    } else {
      router.push('/trial/user');
    }
  }

  handleLoginButton = () => {
    const { router } = this.props;

    router.push('/auth');
  }


  renderRoleButtons = () => (
    <div>
      {this.profileOptions.map((option, key) => (
        <div onClick={() => this.handleRoleClick(option.profileType)} key={key} className="free-profile-button" style={{ ...styles.button }}>
          <Image src={asset(option.assets)} style={{ width: '40px', height: '40px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
            <span style={{ marginBottom: '3px', fontStyle: 'normal', fontWeight: 'bold', fontSize: 15 }}>{option.title}</span>
            <span style={{ fontSize: 12 }}>{option.subtitle}</span>
          </div>
        </div>
      ))}
    </div>
  )

  render() {
    const { loading } = this.state;
    if (loading) {
      return (<div id="ProspectProspect">
        <Divider hidden />
        <div style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
          <Loader active inline />
        </div>
      </div>
      );
    }

    return (
      <div id="ProspectProspect">
        <div style={{ width: '100%' }}>
          <div style={{ maxWidth: '420px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
              <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 0, marginTop: 0 }}>{__('Profile choice')}</p>
              <p style={{ marginBottom: 15, fontSize: 15, color: '#6C6C6C' }}>{__('What is your role in the institution you belong to?')}</p>
              {this.renderRoleButtons()}
              <Button fluid style={{ ...styles.footer }} loading={loading} onClick={() => this.handleLoginButton()}>
                <span style={{ fontSize: '15px' }}>{__('I`m already registered')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
