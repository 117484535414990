import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Button, Popup, Divider, Container } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { execute, makePromise } from 'apollo-link';
import cookie from 'react-cookie';
import { graphql } from 'react-apollo';

import i18n, { __ } from '../../i18n';
import * as utils from '../../utils';

import Timer from '../../components/Timer';
import TextInput from '../../components/TextInput';
import Responsive from '../../components/Responsive';

const styles = {
  button: {
    borderRadius: '40px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#FFF',
    color: '#212529',
    border: 'solid 1px #e9ecef'
  },
  mobileButton: {
    borderRadius: '40px',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#FFF',
    color: '#212529',
    border: 'solid 1px #e9ecef'
  },
  primary: {
    backgroundColor: '#0069FF',
    color: '#FFF'
  }
};


@graphql(gql`mutation createCodeAndSend($input: CreateCodeAndSendInput!) {
  createCodeAndSend(input: $input){
    status
  }
}
`, { name: 'createCodeAndSend' },
)

@graphql(gql`mutation validateCode($input: validateCodeInput!){
  validateCode(input: $input){
    isValid
  }
}
`, { name: 'validateCode' }
)

@graphql(gql`mutation findUserPhone($phone: String, $invite: Boolean, $isNewCode: Boolean) {
  findUser(phone: $phone, invite: $invite, source: WEB, isNewCode: $isNewCode) {
    __typename
    ... on User {
        fullname
        email
        phone
        hasPassword
      }
      ... on PublicAddress {
        address
      }
  }
}`, { name: 'findUserPhone' },
)
@inject('store', 'api', 'client') @observer
export default class FreeCode extends Responsive {
  state = {
    error: false,
    code: '',
    waiting: false,
    loading: true
  };

  componentDidMount() {
    const { store } = this.props;
    const user = store && store.prospect && store.prospect.user;
    if (!user) this.props.router.push('/trial');

    this.setState({ loading: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
  }

  validate = () => {
    const { code } = this.state;
    const valid = code && code.length === 6;

    if (!valid) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false }, () => {
        this.submit();
      });
    }
  }

  submit = async () => {
    this.setState({ loading: true });

    const { code } = this.state;
    const { router, store } = this.props;

    try {
      const response = await this.props.validateCode({
        variables: {
          input: {
            code,
            address: store.prospect.user.phone
          }
        }
      });

      this.setState({ loading: false });
      if (response.data.validateCode.isValid) {
        store.prospect.user.code = code;
        router.push('/trial/organization');
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError('Code not found'), success: false };
      }

    } catch (err) {
      this.setState({ loading: false });
      if (err.graphQLErrors[0]) this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  };

  resendCode = () => {
    this.setState({ waiting: true, loading: true });
    const { phone } = this.props.store.prospect.user;
    this.props.createCodeAndSend({
      variables: {
        input: {
          address: phone,
          type: 'phone',
          language: i18n.currentLocale()
        }
      }
    }).then(() => {
      this.setState({ loading: false });
    })
      .catch((err) => {
        this.setState({ waiting: false, loading: false });
        this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0] ? err.graphQLErrors[0] : err), success: false };
      });
  };

  renderForm = () => {
    const { code, error } = this.state;
    const { store } = this.props;
    const phoneFormatted = store && store.prospect && store.prospect.user && store.prospect.user.phoneFormatted;

    return (
      <div style={{ paddingBottom: 24 }}>
        <Form>
          <div style={{ display: 'flex', alignItems: 'left', flexFlow: 'column' }}>
            <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 8, marginTop: 0 }}>{__('Validate your phone')}</p>
            <p style={{ marginBottom: 40, fontSize: 15, color: '#6C6C6C' }}>
              {__('We sent you a SMS with a 6-digit access code for the number')}
              <br />
              <span style={{ color: '#1D1D1D', fontWeight: 'bold' }}>{phoneFormatted}</span>
            </p>
          </div>
          <TextInput
            autoComplete="off"
            name="code"
            placeholder="* * * * * *"
            label={__('Enter the 6-digit code below to validate it:')}
            value={code}
            maxLength={6}
            onChange={(e, data) => this.setState({ code: data.value })}
            error={error && __('The code must have 6 digits')}
            style={{ height: 50 }}
            className="code-checker"
          />
          {this.state.waiting && <Container style={{ border: '1px solid #e9ecef', borderRadius: '5px', padding: '16px' }}>
            {__('We sent you an SMS with a link. Check your messages for instructions')}
          </Container>}
        </Form>
      </div>
    );
  }

  renderFooter = () => {
    const { waiting, loading } = this.state;

    const style = this.isMobile() ? styles.mobileButton : styles.button;

    return (
      <div style={{ textAlign: 'center' }}>
        <Button type="submit" fluid style={{ ...style, ...styles.primary, marginBottom: 12 }} loading={loading} onClick={this.handleSubmit}>
          <span style={{ fontSize: '15px' }}>{__('Next')}</span>
        </Button>
        <span style={{ cursor: 'pointer', color: '#0069FF', display: 'inline-block', marginTop: '14px' }} disabled={waiting}>
          <span style={{ fontSize: '15px' }}>
            {waiting ? <Timer
              interval={30}
              content={__('Wait')}
              textColor="#212529"
              onFinishTimer={() => this.setState({ waiting: false })}
            />
              : <span onClick={() => this.resendCode()}>{__('I didn\'t receive the code')}</span>}
          </span>
        </span>
      </div>);
  }

  render() {
    return (
      <div style={{ maxWidth: '460px', marginLeft: 'auto', marginRight: 'auto' }}>
        {this.renderForm()}
        {this.renderFooter()}
      </div>
    );
  }
}
