import { roleOptions } from '../getRoleOptions';

describe('roleOptions', () => {
  it('should return roleOptions', () => {
    expect(roleOptions()).toEqual([
      { text: 'Coordinator', value: 'COORDINATION' },
      { text: 'Director', value: 'DIRECTOR' },
      { text: 'Employee', value: 'EMPLOYEE' },
      { text: 'Mantainer', value: 'MANTAINER' },
      { text: 'Teacher', value: 'TEACHER' },
      { text: '', value: 'OTHER' },
    ]);
  });
});
