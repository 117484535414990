import * as utils from '../../../utils';
import { __ } from '../../../i18n';

export function typeOptions(organizationType) {
  return [
    {
      key: 0,
      text:
        utils.checkIfItIsClientOrSchool(organizationType) === 1
          ? __('Student')
          : __('Client'),
      value: 'STUDENT',
      'data-value': 'STUDENT',
    },
    { key: 1, text: __('Staff'), value: 'STAFF', 'data-value': 'STAFF' },
  ];
}
