import { typeOptions } from '../getTypeOptions';
import { __ } from '../../../../i18n';

describe('getTypeOptions', () => {
  it('should return an array of type options', () => {
    const options = typeOptions();
    expect(options).toEqual([
      {
        text: __('Student'),
        key: 0,
        value: 'STUDENT',
        'data-value': 'STUDENT',
      },
      { text: __('Staff'), key: 1, value: 'STAFF', 'data-value': 'STAFF' },
    ]);
  });
});
