import { __ } from '../../../i18n';

export function roleOptions() {
  return [
    { text: __('Coordinator'), value: 'COORDINATION' },
    { text: __('Director'), value: 'DIRECTOR' },
    { text: __('Employee'), value: 'EMPLOYEE' },
    { text: __('Mantainer'), value: 'MANTAINER' },
    { text: __('Teacher'), value: 'TEACHER' },
    { text: __(''), value: 'OTHER' },
  ];
}
