import React from 'react';
import cookie from 'react-cookie';
import { inject, observer } from 'mobx-react';
import { Form, Button } from 'semantic-ui-react';
import axios from 'axios';

import TextInput from '../../components/TextInput';
import Dropdown from '../../components/Dropdown';
import Responsive from '../../components/Responsive';
import Modal from '../../components/Modal';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';

import { __ } from '../../i18n';

const errorMap = {
  fullname: __('Institution\'s name is required'),
  type: __('Required field'),
  size: __('Required field'),
  role: __('Required field')
};

const styles = {
  error: {
    color: 'red'
  },
  form: {
    maxWidth: '460px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '10px'
  },
  segment: {
    padding: '24px',
    border: 'none'
  },
  footer: {
    position: 'static',
    borderTop: '0px',
    maxWidth: '460px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px'
  }
};

const sizeOptions = () => [
  { text: __('less than 50 students'), value: '0-50', 'data-value': '0-50' },
  { text: __('from 51 to 200 students'), value: '51-200', 'data-value': '51-200' },
  { text: __('from 201 to 650 students'), value: '201-650', 'data-value': '201-650' },
  { text: __('from 651 to 2000 students'), value: '651-2000', 'data-value': '651-2000' },
  { text: __('more than 2000 students'), value: '2000+', 'data-value': '2000+' },
];

const roleOptions = [
  { text: __('Coordinator'), value: 'COORDINATION' },
  { text: __('Director'), value: 'DIRECTOR' },
  { text: __('Employee'), value: 'EMPLOYEE' },
  { text: __('Mantainer'), value: 'MANTAINER' },
  { text: __('Others'), value: 'OTHER' }
];

@inject('store') @observer
export default class Organization extends Responsive {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      size: null,
      role: '',
      profileType: '',
      loading: true,
      error: false,
      checked: false
    };
  }

  componentDidMount() {
    this.setState({ loading: false });

    const { store } = this.props;
    const user = store && store.prospect && store.prospect.user;
    const organization = store && store.prospect && store.prospect.organization;

    if (!user) this.props.router.push('/trial');

    const profileType = user && user.profileType;
    const role = user && user.role ? user.role : '';

    this.setState({
      role,
      profileType
    });

    if (organization) {
      const { fullname, size, checked } = organization;
      this.setState({
        fullname,
        size,
        checked
      });
    }
  }

  validate = () => {
    this.setState({ loading: true });
    const { fullname, size, role } = this.state;
    const validFullname = !!fullname.trim();
    const validSize = !!size;
    const validRole = !!role;

    if (validFullname && validSize && validRole) {
      this.submit();
    } else {
      this.setState({ error: true, loading: false });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
  }

  handleTerm = (e) => {
    e.preventDefault();

    const isMobile = this.isMobile();
    const { router, store } = this.props;
    const { fullname, size, checked } = this.state;

    if (isMobile) {
      store.prospect.organization = {
        ...store.prospect.organization,
        fullname,
        size,
        checked
      };

      router.push('/trial/terms');
    } else {
      this.props.store.appends.push(
        <Modal
          closeIcon={'times close inside'}
          header={__('Terms of Service')}
          onClose={() => this.props.store.appends.pop()}
          scrolling
          content={<TermsAndPrivacy noHeader />}
        />);
    }
  }

  sendFormToHubspot = async (user, organization, isMobile) => {
    const FORM_ID = 'aaa66816-f541-4af8-87e5-0809c359a0c4';
    const HUBSPOTUTK = cookie.load('hubspotutk'); // campo usado pelo hubspot para trackear o usuario

    await axios({
      method: 'post',
      url: `https://api.hsforms.com/submissions/v3/integration/submit/5967222/${FORM_ID}`,
      data: {
        fields: [
          { name: 'fullname', value: user.fullname },
          { name: 'email', value: user.email },
          { name: 'mobilephone', value: user.phone },
          { name: 'company', value: organization.fullname },
          { name: 'ocupa_o', value: user.role },
          { name: 'alunos_predefinido', value: organization.size },
          { name: 'optinwhatsapp', value: user.checked || organization.checked ? 'true' : 'false' },
          { name: 'ismobile', value: isMobile ? 'true' : 'false' }
        ],
        context: {
          hutk: HUBSPOTUTK,
          pageUri: 'https://classapp.com.br/trial/organization',
          pageName: `${__('Sign Up')} · ClassApp`
        }
      }
    }).catch(e => console.error(e));
  }

  submit = async () => {
    const { store, router } = this.props;
    const { fullname, size, checked, role } = this.state;

    store.prospect.organization = {
      ...store.prospect.organization,
      fullname,
      size,
      checked,
      tzOffset: -(new Date().getTimezoneOffset())
    };

    store.prospect.user = {
      ...store.prospect.user,
      role
    };

    const { organization } = store.prospect;

    let user = store.currentUser;
    if (store.prospect.user && store.prospect.user.email) user = store.prospect.user;

    if (store.app && store.app.env === 'production') {
      this.sendFormToHubspot(user, organization, this.isMobile());
    }
    store.prospect.organization.fullname = this.state.fullname;
    router.push('/trial/entities');
    this.setState({ loading: false });
  };

  renderFooter = () => {
    const profileType = this.props.store.prospect.user && this.props.store.prospect.user.profileType;
    const { loading, fullname, size, role } = this.state;

    const disabled = !fullname || !size || (!role && profileType === 'INSTITUTION');

    return (
      <div style={{ ...styles.footer }}>
        <Button className="conversao-mqlfree" disabled={disabled} fluid style={{ backgroundColor: '#0069FF', borderRadius: '40px', color: '#FFF', paddingTop: '20px', paddingBottom: '20px' }} loading={loading} onClick={this.handleSubmit}>
          <span style={{ fontSize: '15px' }}>{__('Next step')}</span>
        </Button>
      </div>
    );
  }

  render() {
    const { fullname, size, role, error } = this.state;
    const { store } = this.props;
    return (
      <div>
        <div>
          <div id="FreeOrganization" style={styles.form}>
            <Form>
              <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 8, marginTop: 0 }}>
                {__('About your institution')}
              </p>
              <p style={{ marginTop: 0, marginBottom: 30, fontSize: 15, color: '#6C6C6C' }}>{__('You will be the admin of the institution.')}</p>
              <TextInput
                name="fullname"
                placeholder={__('Write your institution\'s name here...')}
                label={__('Institution\'s name')}
                onChange={(e, data) => this.setState({ fullname: data.value })}
                value={fullname}
                error={error && fullname.trim() === '' && errorMap.fullname}
              />
              <Dropdown
                upward
                name="size"
                label={__('Size')}
                selection
                data-value={size}
                value={size}
                placeholder={__('Select')}
                options={sizeOptions()}
                onChange={(e, data) => this.setState({ size: data.value })}
                error={error && size === null && errorMap.size}
              />
              {
                store.prospect.user && store.prospect.user.profileType === 'INSTITUTION' && <Dropdown
                  label={__('Your role')}
                  name="role"
                  value={role}
                  options={roleOptions}
                  fluid
                  selection
                  placeholder={__('Select')}
                  error={error && !role && errorMap.role}
                  onChange={(e, data) => this.setState({ role: data.value })}
                />
              }
            </Form>
          </div>
        </div>
        <div>
          {this.renderFooter()}
          {this.props.children}
        </div>
      </div>
    );
  }
}
