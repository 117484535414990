/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import Responsive from '../../components/Responsive';

import i18n, { __ } from '../../i18n';
import config from '../../../../config/server.config';
import * as utils from '../../utils';

const styles = {
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyCOntent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  footer: {
    position: 'static',
    borderTop: '0px',
    maxWidth: '460px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px'
  },
  mobileVideo: {
    width: 343,
    height: 200,
    marginTop: '20px',
    marginBottom: '20px'
  },
  desktopVideo: {
    width: 607,
    height: 309
  }
};

@inject('store') @observer
export default class ProspectFinish extends Responsive {
  static fetchData({ store }) {
    store.app.title = __('Finish');
  }

  UNSAFE_componentWillMount() {
    const { finished } = this.props.store.prospect;
    if (!finished) {
      this.props.router.push('/trial');
    }
  }

  handleSubmit = (e) => {
    const { router, store } = this.props;
    const { finished } = store.prospect;
    e.preventDefault();

    if (finished.organizationId) {
      router.push(`/organizations/${finished.organizationId}/messages`);
    } else {
      this.props.router.push('/');
    }
  }

  renderFooter = () => {
    if (this.isMobile()) {
      const mobileStore = i18n.locale === 'pt' ? {
        android: { badge: config.android.badge.pt, link: config.android.link.br },
        ios: { badge: config.ios.badge.pt, link: config.ios.link.br }
      } :
        {
          android: { badge: config.android.badge.en, link: config.android.link.us },
          ios: { badge: config.ios.badge.en, link: config.ios.link.us }
        };

      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {utils.isIOSDevice() ?
            <a target="_blank" rel="noreferrer noopener" href={`${mobileStore.ios.link}?mt=8`} style={{ display: 'inline-block', overflow: 'hidden', background: `url(${mobileStore.ios.badge}?releaseDate=2014-10-31&kind=iossoftware&bubble=ios_apps) no-repeat`, width: '135px', height: '40px' }} />
            : <a target="_blank" rel="noreferrer noopener" href={`${mobileStore.android.link}`}><img style={{ width: '135px', height: '60px' }} alt="Get it on Google Play" src={mobileStore.android.badge} /></a>}
        </div>);
    }

    return (
      <div style={{ ...styles.footer }}>
        <Button
          primary
          fluid
          style={{ width: '460px', backgroundColor: '#0069FF', borderRadius: '40px', color: '#FFF', paddingTop: '20px', paddingBottom: '20px' }}
          onClick={this.handleSubmit}
        >
          <span style={{ fontSize: '15px' }}>
            {__('Take me to my ClassApp')}
          </span>
        </Button>
      </div>
    );
  }

  renderHeader = () => {
    if (this.isMobile()) {
      return (
        <div>
          <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#000000', marginBottom: 10, marginTop: 0 }}>
            {__('All ready! Have the best experience when')}
            {' '}
            <span style={{ color: '#0069FF' }}>{__('downloading our app!')}</span>
          </p>
          <p style={{ fontStyle: 'normal', color: '#6C6C6C', fontWeight: '500', fontSize: '15px', lineHeight: '19px', textAlign: 'center' }}>
            {__('In addition to downloading it is very important that you')}
            {' '}
            <span style={{ color: '#000000' }}>{__('access ClassApp from your computer')}</span>
            {' '}
            {__('to add new students and staff')}
          </p>
        </div>
      );
    }

    return (
      <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#000000', marginBottom: 10, marginTop: 0 }}>
        {__('All ready!')}
      </p>);
  }

  render() {
    const videoStyle = this.isMobile() ? styles.mobileVideo : styles.desktopVideo;

    return (
      <div>
        <div id="ProspectFinish" style={styles.form}>
          {this.renderHeader()}
          <iframe
            style={{ ...videoStyle }}
            src="https://www.youtube.com/embed/b5KUo8F4YoI"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}
