import { __ } from '../../../i18n';

export function defaultTable() {
  return [
    [
      { value: '1', readOnly: true },
      { value: '', placeHolder: __('Enter a name here') },
      { value: '', placeHolder: __('Eg: (00) 0 0000-0000') },
      { value: 'STAFF' },
    ],
    [
      { value: '2', readOnly: true },
      { value: '', placeHolder: __('Enter a name here') },
      { value: '', placeHolder: __('Eg: (00) 0 0000-0000') },
      { value: 'STAFF' },
    ],
  ];
}
